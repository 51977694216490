import React, { useState } from 'react';
import { ReusableProvider } from 'reusable';
import { Button } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { MenuBar } from "../../components/MenuBar/MenuBar.jsx";
import { getDefaultWallets, RainbowKitProvider, midnightTheme } from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient, WagmiConfig, } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { DisplayRegens } from "../../components/Regens/DisplayRegens";
import { MintButtonSeason1 } from "../../components/MintButtons/Season1/MintButtonSeason1";
import './HomePage.css';
import '@rainbow-me/rainbowkit/styles.css';

const { chains, provider } = configureChains(
  [ chain.mainnet, chain.goerli ],
  [ 
    alchemyProvider({ apiKey: 'IC_ntRsmFeKPzCI82CrPouCWmiDp-JNe' }),
    publicProvider() 
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Tony Millionaire ETH mint',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

const HomePage = () => {

  const [ toggleButton , setToggleButton ] = useState("mintPage")

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={midnightTheme()} modalSize="compact">
        <ReusableProvider>
          <div>
          <CssBaseline />
            <div>
              <MenuBar />        
            </div>
            {
              toggleButton === "regensPage" ?
            <div style={{textAlign: "center"}}>
              <Button variant="outlined" onClick={()=>setToggleButton("mintPage")} >MINT!!</Button>
              <DisplayRegens />
            </div>              
            :
            <div style={{textAlign: "center"}}>
              <MintButtonSeason1 />
              <hr />
              <Button variant="outlined" onClick={()=>setToggleButton("regensPage")} >Show Regens</Button>
            </div>
            }
          </div>
        </ReusableProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default HomePage;
