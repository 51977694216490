import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useConnectModal, useAccountModal, useChainModal,  } from '@rainbow-me/rainbowkit';
import { useAccount, useContractWrite } from 'wagmi';
import { ethers } from "ethers";
import { merkleProof } from "../../utils/whitelist";
import abiFile from "../../abis/season1/NFT_ERC721.json";
import phase1 from "../../whitelists/season1/phase1.json";
import phase2 from "../../whitelists/season1/phase2.json";
import freemint from "../../whitelists/season1/freeMint.json";
import mint_button from "../../Assets/mint_button.png";
import CircularProgress from '@mui/material/CircularProgress';

export const MintButtonSeason1 = () => {
  const { openChainModal } = useChainModal();
  const { isConnected, address } = useAccount();
  const [ mintLoading, setMintLoading ] = useState(false);
  const [ minStatus, setMintStatus] = useState();
  const [ mintErrorStatus, setMintErrorStatus ] = useState();
  const [ statusSucces, setStatusSuccess ] = useState();
  const [ amountPhase1, setAmountPhase1 ] = useState(1);
  const [ amountPhase2, setAmountPhase2 ] = useState(1);
  const [ amountPublic, setAmountPub ] = useState(1);

  // const CONTRACT_ADDRESS = "0xB91281f2356be8A93a8F8BA64DAB26a6961Ac5E4"; // Test Addres
  // const CONTRACT_ADDRESS = "0x1b23e5C8D0eC7dECD15c4f932f84F7a1282F8a6d";
  const CONTRACT_ADDRESS = "0x6d6ee5681d835b93c94bf6ba60c26f8e00733c0e";

  const contractConfig = {
    addressOrName: CONTRACT_ADDRESS,
    contractInterface: abiFile.abi,
    args: []
  };

  const { writeAsync: OGMint, error: OGMintError } = useContractWrite({
      ...contractConfig,
      functionName: 'OGMint',
      overrides: {
        value: String(38000000000000000 * amountPhase1)
      }
  });

  const { writeAsync: collabMint, error: collabMintError } = useContractWrite({
    ...contractConfig,
    functionName: 'collabMint',
    overrides: {
      value: String(38000000000000000 * amountPhase2)
    }
  });

  const { writeAsync: publicMint, error: publicMintError } = useContractWrite({
    ...contractConfig,
    functionName: 'publicMint',
    overrides: {
      value: String(38000000000000000 * amountPublic)
    }
  });

    const { writeAsync: freeMint, error: freeMintError } = useContractWrite({
    ...contractConfig,
    functionName: 'freeMint'
  });  

  const OGMintClick = async () => {
    contractConfig.args.push(amountPhase1);
    const proof = merkleProof( address, phase1.phase1 );
    console.log("Proof", proof);
    contractConfig.args.push(proof);
    console.log("contract config", contractConfig);
    setStatusSuccess();
    setMintErrorStatus();
    setMintLoading(true);
    try {
      const tx = await OGMint({
        gasLimit: String(300000 * amountPublic)
      });
      const receipt = await tx.wait();
      console.log("receipt", { receipt });
      receipt && setStatusSuccess(receipt);
    } catch (error) {
      console.error("Mint Error", error);
      setMintErrorStatus(error);
      setMintLoading(false);
    } finally {
      setMintLoading(false);
    }
  };

  const collabMintClick = async () => {
    contractConfig.args.push(amountPhase2);
    const proof = merkleProof( address, phase2.phase2 );
    console.log("Proof", proof);
    contractConfig.args.push(proof);
    console.log("contract config", contractConfig);
    setStatusSuccess();
    setMintErrorStatus();
    setMintLoading(true);
    try {
      const tx = await collabMint({
        gasLimit: String(300000 * amountPublic)
      });
      const receipt = await tx.wait();
      console.log("receipt", { receipt });
      receipt && setStatusSuccess(receipt);      
    } catch (error) {
      console.error("Mint Error", error);
      setMintErrorStatus(error);
      setMintLoading(false);
    } finally {
      setMintLoading(false);
    }
  };

  const freeMintClick = async () => {
    const proof = merkleProof( address, freemint.freemint );
    console.log("Proof", proof);
    contractConfig.args.push(proof);
    console.log("contract config", contractConfig);
    setStatusSuccess();
    setMintErrorStatus();
    setMintLoading(true);
    try {
      const tx = await freeMint({
        gasLimit: String(300000 * amountPublic)
      });
      const receipt = await tx.wait();
      console.log("receipt", { receipt });
      receipt && setStatusSuccess(receipt);      
    } catch (error) {
      console.error("Mint Error", error);
      setMintErrorStatus(error);
      setMintLoading(false);
    } finally {
      setMintLoading(false);
    }
  };

  const publicMintClick = async () => {
    // const proof = merkleProof( address );
    // console.log("Proof", proof);
    // contractConfig.args.push(proof);
    contractConfig.args.push(amountPublic);
    console.log("contract config", contractConfig);
    setStatusSuccess();
    setMintErrorStatus();
    setMintLoading(true);

    try {
      const tx = await publicMint({
        gasLimit: String(300000 * amountPublic)
      });

      const receipt = await tx.wait();
      console.log("receipt", { receipt });
      receipt && setStatusSuccess(receipt);    

    } catch (error) {
      console.error("Mint Error", error);
      setMintErrorStatus(error);
      setMintLoading(false);
    } finally {
      setMintLoading(false);
    }
  };

  const DisplayListStatus = () => {
    return (
      <div style={{color: "#ffffff", width: "30%", margin: "0 auto"}} >
        { mintLoading && <div style={{color: "#ffffff"}}>Minting <br /> <CircularProgress color="success" /> </div> }
        { statusSucces && <div style={{color: "#ffffff"}}>YOU DID IT, YOU WENT FROM DEGEN TO REGEN<br/>Tx Hash: <a href={"https://etherscan.io/tx/"+statusSucces.transactionHash} target="_blank" >{statusSucces.transactionHash}</a></div> }
        { mintErrorStatus && <div style={{color: "#ffffff"}}>{mintErrorStatus.reason}</div> }  
      </div>
    )
  };

  const DisplayTimes = () => {
    const isOg = phase1.phase1.includes(address);
    const isCollab = phase2.phase2.includes(address);
    const isFreeMint = freemint.freemint.includes(address);
    const OGStartTime     = 1669914000; //12pmEST = 1669914000
    const collabStartTime = 1669917600; //1pmEST = 1669917600
    const publicStartTime = 1669924800; //3pmEST = 1669924800
    const date = (Date.now()/1000);

    return (
      <div style={{color: "#ffffff", width: "50%", margin: "0 auto"}} >
        {/*
        Phase 1 (OG & Partners): 12:00 PM EST | 6:00 PM CET<br />
        {
        <>
          <Button variant="contained" onClick={OGMintClick}><img src={mint_button} alt="mint button" height="40" /></Button>
          <TextField
            id="amountPhase1"
            label="Amount"
            type="number"
            value={amountPhase1}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { min: 1, max: 3 } }}
            sx={{ input: { color: 'white', width: 50, height: 20, textAlign: "center" } }}
            onChange={event => setAmountPhase1(event.target.value) }
          />
          {(0.076 * amountPhase1).toFixed(6)}ETH  
          <br />
          Cost Per: 0.076 ETH
        </>
        }
        <hr />
        Phase 2 (Collabs): 1:00 PM EST | 7:00 PM CET<br />
        {
        <>
          <Button variant="contained" onClick={collabMintClick}><img src={mint_button} alt="mint button" height="40" /></Button>
          <TextField
            id="amountPhase2"
            label="Amount"
            type="number"
            value={amountPhase2}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { min: 1, max: 3 } }}
            sx={{ input: { color: 'white', width: 50, height: 20, textAlign: "center",  } }}
            onChange={event => setAmountPhase2(event.target.value) }
          />
          {(0.076 * amountPhase2).toFixed(6)}ETH  
          <br />
          Cost Per: 0.076 ETH             
        </>
        }
        <hr />
        */}
        <div>
          Mint
        </div>
        <hr />       
        {
          <>
          <Button variant="contained" onClick={publicMintClick}><img src={mint_button} alt="mint button" height="40" /></Button>
          <TextField
            id="amountPublic"
            label="Amount"
            type="number"
            value={amountPublic}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { min: 1, max: 3 } }}          
            sx={{ input: { color: 'white', width: 50, height: 20, textAlign: "center", borderColor: "yellow !important" } }}
            onChange={event => setAmountPub(event.target.value) }
          />
          {(0.038 * amountPublic).toFixed(6)}ETH  
          <br />
          Cost Per: 0.038 ETH
          </>
        }
        {
          /*
          <>
            <hr />
            If you owned 5 or more Regens before the snapshot time: November 30 2022, 7:00 PM EST, <br /> you are eligible for a Free Mint.<br />
            <Button variant="contained" onClick={freeMintClick}><img src={mint_button} alt="mint button" height="40" /></Button>
          </>
          */
        }
      </div>
    )
  };

  const Newdate = new Date();
  const utcStr = Newdate.toLocaleTimeString('en-US', {timeZone: "EST"});

  return (
    <>
      <div style={{color: "#ffffff", width: "100%", margin: "0 auto"}} >
        Degen -- Season 1 Mint in progress. <br />
        <br />
        Season 1 reveal begins December 21st.
        <br />
        <br />
        <a style={{color: "#ffffff"}} href="https://etherscan.io/address/0x6d6ee5681d835b93c94bf6ba60c26f8e00733c0e" alt="contract" target="_blank" >0x6d6ee5681d835b93c94bf6ba60c26f8e00733c0e</a><br />
        {utcStr} EST
        <br/>
        <DisplayListStatus />
        <br/>
        <DisplayTimes />
      </div>
    </>
  );
}
