import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useConnectModal, useAccountModal, useChainModal, ConnectButton,  } from '@rainbow-me/rainbowkit';
import { useAccount, useContractRead, useContractWrite } from 'wagmi';

export const EthWallet = () => {
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();
  const { isConnected, address } = useAccount();

  useEffect(()=>{
    console.log("isConnected", isConnected);
    console.log("address", address);
    // !isConnected && openConnectModal()    
  }, [])

  return (
    <>
      <ConnectButton />
    </>
  );
}
