import React, { useState, useEffect } from 'react';
import { AppBar, Box, Toolbar, Typography, IconButton } from '@mui/material';
import { EthWallet } from "../ETHWallet/EthWallet";
import tonyLogo from "../Assets/logo2.jpg";
export const MenuBar = () => {

  return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" >
          <Toolbar style={{background: "#000000", color: "#ffffff"}}>
            <Typography className="h3" style={{textAlign: "left", fontfamily: 'Aldrich'}} component="div" sx={{ flexGrow: 1 }} >
              <img src={tonyLogo} alt="Tony Millionaire Regen Mint" height="150" />
            </Typography>
            <div>
              <EthWallet />
            </div>
          </Toolbar>
        </AppBar>
        <hr />
      </Box>
  );
}
