import React, { useEffect, useState } from 'react';
import { moralisApi } from "../../api/MoralisApi";
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { Stack, Divider, Button } from '@mui/material';
import { getRegenName, getRegenNumber } from "../utils/regenUtils";
import { alchemyApiGet } from "../../api/alchemyAPI";

export const DisplayRegens = () => {
  const { isConnected, address } = useAccount();
  const [ regens, setRegens ] = useState();
  const [ showAttributes, setShowAttributes ] = useState({});


  const getRegens = async () => {
    setShowAttributes({});
    isConnected === true && await queryTheRegens();
  };

  const queryTheRegens = async () => {
    const genesis = "0x8f3ff4bebab846ab06e487b2aac28e12e4dbbc2c";
    const season1 = "0x6d6ee5681d835b93c94bf6ba60c26f8e00733c0e";
    console.log("isConnected", isConnected);
    console.log("address", address);
    const uri = `getNFTs?owner=${address}&contractAddresses[]=${genesis}&contractAddresses[]=${season1}&omitMetadata=false`

    const getRegens = await alchemyApiGet(uri);
    console.log("regens", getRegens.ownedNfts);
    setRegens(getRegens.ownedNfts);
    return(getRegens.ownedNfts);
  };

  const ConnectWallet = () => {
    return(
      <div style={{color: "#ffffff", width: "30%", margin: "0 auto"}} >
        DEGEN YOU MADE IT!!!!<br />
        Connect you ETH wallet to view your Regen NFTs.<br />
      </div>
    )
  };

  const DisplayIPFSmedia = ( {image} ) => {
    // console.log("image", image );
    const ipfsGateway = "https://ipfs.onchainapps.io/ipfs/";
    return(
      <>
        <a href={`${ipfsGateway}${image.replace("ipfs://","")}`} alt="" target="_blank"><img src={`${ipfsGateway}${image.replace("ipfs://","")}`} alt="regen" height="100" /></a>
      </>  
    )
  };

  const getRegenName = ( asset ) => {
    // console.log("regenName", asset.slice(0, -5));
    return( asset.slice(0, -5) );
  };

  const DisplayMoreData = ( {regen} ) => {
    return(
      <div className="rainbow-border2" style={{ position: "absolute", zIndex: 999, background: "#000", opacity: "0.9", padding: "5px", left: "40%", top: "20%", width: 300 }}>
        < DisplayIPFSmedia image={regen.metadata[`mandala-qr-code`]} />
      </div>      
    )
  };

  useEffect( () => {
    getRegens();
  }, []);

  const ShowRegens = () => {
    return(
      <Stack style={{ overflow: "auto" }} direction="row" spacing={2} justifyContent="flex-start" alignItems="center" color divider={<Divider orientation="vertical" flexItem />}>
      {
        regens && regens.length > 0 && 
        regens.map(( regen ) => 
          <div style={{color: "#FFFFFF", width: 300}}>
            <div>
              {regen.title !== "" ? getRegenName(regen.title) : <>Reveal<br />Coming<br /> Dec 18</> }<br />
            </div>
            <div>
              <DisplayIPFSmedia image={regen.metadata.image} />
            </div>
            <div>
              {
                showAttributes[regen.id.tokenId] === true && 
                <div>
                  <DisplayIPFSmedia image={regen.metadata[`mandala-qr-code`]} />
                  <DisplayIPFSmedia image={regen.metadata[`Regens-chart`]} />
                </div>

              }
            </div>
            { !showAttributes[regen.id.tokenId] && <Button onClick={ ()=>setShowAttributes({ [regen.id.tokenId]: true }) } >MORE?</Button> }
          </div>
        )
      }
      </Stack>
    );
  }

  return (
    <div>
      { isConnected === false ? 
        <ConnectWallet />
        :
        <ShowRegens />
      }
    </div>
  );
}